import React, { Fragment, useState, useEffect } from 'react'
import { t } from 'i18next'
import { Row, Button } from 'reactstrap'

import { linkerFilter } from '../../helpers/fields'
import { isNull } from '../../izUtils'

const Filter = ({filterFields, filters, getData, lsFilterName, clearData}) => {
    const [clearLoading, setClearLoading] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        setConfirmLoading(false);
        setClearLoading(false);
    
    }, [filters])
    
    const textChange = (value, name) => {
        let clonedFilters = {...filters}
        clonedFilters[name].value = value;
        if (!isNull(clonedFilters['pagination_page'])) clonedFilters['pagination_page'].value = 1;

        if (name === 'client' || name === 'facility') {
            if (!isNull(clonedFilters['search'])) clonedFilters['search'].value = "";
            if (name === 'client' && !isNull(clonedFilters['facility'])) clonedFilters['facility'].value = "";
        } 

        getData(clonedFilters)
    }

    const autocompleteHandler = (value, name, save) => {
        let clonedFilters = {...filters}
        clonedFilters[name].value = value;
        clonedFilters['pagination_page'].value = 0;

        if (name === 'search' && save) {
            if (!isNull(clonedFilters['client'])) clonedFilters['client'].value = "";
            if (!isNull(clonedFilters['facility'])) clonedFilters['facility'].value = "";
        } 

        getData(clonedFilters)
    }

    const confirmFilters = () => {
        setConfirmLoading(true)
        let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
        if (!isNull(lsFilter['pagination_page'])) lsFilter['pagination_page'].value = 1;
        getData(lsFilter)
    }

    const clearFilters = () => {
        setClearLoading(true)
        clearData()
    }

    const mapLinkerFilter = (field) => {
        const inputlinkerFields = {
            field,
            lsFilterName,
            inputs: filters,
            col: {md: "6", lg: "6", xl: "4"},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            autocompleteHandler: (value, id, save) => autocompleteHandler(value, id, save),
        }

        return linkerFilter(inputlinkerFields);
    }
    
    return (
        <Fragment>
            <Row>{ filterFields.map(field => mapLinkerFilter(field)) }</Row>
            <div className='d-flex flex-wrap'>
                {confirmLoading ?
                    <div className="loader-box justify-content-start ms-4 me-4" style={{ height: 'auto' }}>
                        <div className="loader-3 loader-secondary" style={{ height: '27px', width: '27px' }}></div>
                    </div>
                    :
                    <Button color="secondary" className="btn-sm mt-2 me-2" onClick={confirmFilters} disabled={clearLoading}>{t('confirm')}</Button> 
                }
                {clearLoading ?
                    <div className="loader-box justify-content-start ms-4 me-4" style={{ height: 'auto' }}>
                        <div className="loader-3 loader-danger" style={{ height: '27px', width: '27px' }}></div>
                    </div>
                    :
                    <Button color="danger" className="btn-sm mt-2 me-2" onClick={clearFilters} disabled={confirmLoading}>{t('clear')}</Button>
                }
            </div>
        </Fragment>
    )
}

export default Filter