import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Button, Form, Row, Col, Card, CardBody, CardFooter, Label } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState, multiSelectLoading, saveContractToLinkedSystem } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import { getSingleItem } from '../../helpers/getSingleItem'
import DisplayFields from '../../helpers/displayFields'
import FacilitySecuritySystemList from './lists/FacilitySecuritySystemList'
import Filter from '../filters/Filter'
// import QRCode from '../QRCode/QRCode'

const AddUpdateContract = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId, linkedSystemId, contractId, contractMethod } = useParams()
    
    const headers = useRecoilValue(headersState);
    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const setContractToLinkedSystem = useSetRecoilState(saveContractToLinkedSystem);

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)
    const [contractSystemData, setContractSystemData] = useState(null)
    const [contractSystemFilter, setContractSystemFilter] = useState(null)

    const lsFilterName = 'AKODA.contractSystemFilter';

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);

        console.log("linkedSystemId:",linkedSystemId)
        console.log("contractId:",contractId)
        console.log("contractMethod:",contractMethod)

        if (contractId === 'create' || contractMethod === 'update') {
            updateData(false, null);
        } else if (contractMethod === 'draft') {
            updateData(false, { contract: { value: contractId } });
        } else {
            getSingleItem(headers, contractId, '/api/contract/', Navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                    getContractSystem();
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractId])

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        // Create & Draft
        let url = env.api + '/api/contract/create'
        let method = 'post'
        // Update
        if (contractId !== 'create' && (!isNull(contractMethod) && contractMethod === 'update')) {  
            url = env.api + '/api/contract/' + contractId
            method = 'patch'
        }

        axios[method](url, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        if (!isNull(linkedSystemId)) {
                            setContractToLinkedSystem(response.data.id)
                            Navigate('/clients/'+clientId+'/facilities/'+facilityId+'/linked-systems/'+linkedSystemId)
                        } else {
                            Navigate(-1)
                        }
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
            setMultiLoading(false) // Set loading for field MultiSelect to false
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const getContractSystem = (passedFilters) => {
        let payload = {};
        if (!isNull(passedFilters)) {
            const keys = Object.keys(passedFilters);
            payload = { filters: {} };
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                    payload.filters[keys[i]] = passedFilters[keys[i]].value;
                } else {
                    payload.filters[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/contract/system/' + contractId, payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setContractSystemData(response.data.data);
                if (!isNull(response.data.filters)) {
                    setContractSystemFilter(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getContractSystem();
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};
        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field, place) => {
        const inputlinkerFields = {
            field,
            inputs: place,
            showErrors,
            component: {id: "createContract"},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            multiHandler: (value, id, component) => multiChange(value, id, component),
            dateHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        if ((contractId !== 'create' && isNull(contractMethod)) || contractMethod === 'get') {
            if (!isNull(place[field])) return <DisplayFields key={'display-'+place[field].name} data={place[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    const filterFields = ['facility', 'billing_method', 'security_system', 'manufacturer', 'system', 'central_station']

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{ (contractId === 'create') ? t('contracts.create.title') : ( contractMethod === 'update' ? t('contracts.update.title') : t('contracts.get.title') ) }</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Fragment>
                        <Form className="theme-form">
                            <Row>
                                <Col md="12" lg="8">
                                    <Row>
                                        <Col sm="4">
                                            { ['contract', 'contract_number', 'active_from', 'notice_period', 'price_matching', 'check_price', 'parts_discount', 'response_time'].map(field => mapLinker(field, inputs)) }
                                        </Col>
                                        <Col sm="4">
                                            { ['serial_number', 'active_to', 'payment_term', 'indexing_notes', 'lump_sum_price', 'services_discount', 'intervention_time'].map(field => mapLinker(field, inputs)) }
                                        </Col>
                                        <Col sm="4">
                                            { ['cost_center', 'valid', 'invoicing', 'annual_price', 'annual_lump_sums', 'error_correction_time'].map(field => mapLinker(field, inputs)) }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="12" lg="4">
                                    <Row>
                                        <Col sm="4" lg="6">
                                            { ['working_hour_price', 'overtime_price', 'night_overtime_price', 'sunday_overtime_price', 'sunday_night_overtime_price'].map(field => mapLinker(field, inputs)) }

                                        </Col>
                                        <Col sm="4" lg="6">
                                            { ['travel_price', 'intervention_price', 'advisory_price', 'mileage_price', 'service_set_price', 'programming_price'].map(field => mapLinker(field, inputs)) }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {(contractMethod === 'get') &&
                                ((isNull(contractSystemData)) ?
                                    <Spinner />
                                    :
                                    <Row>
                                        <hr/>
                                        <Label className='display-label'>{t('form.label.securitySystemComponent')}</Label>
                                        {(!isNull(contractSystemFilter)) &&
                                            <Filter filterFields={filterFields} filters={contractSystemFilter} getData={(clonedFilters) => getContractSystem(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                        }
                                        {(contractSystemData.length !== 0) &&
                                            <div className='my-3'>
                                                <FacilitySecuritySystemList data={contractSystemData} headers={headers} />
                                            </div>
                                        }
                                    </Row>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </CardBody>
            {(contractId === 'create' || (!isNull(contractMethod) && (contractMethod === 'update' || contractMethod === 'draft'))) &&
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('contracts.create.save')}</Button>
                    </div>
                </CardFooter>
            }
        </Card>
    )
}

export default AddUpdateContract