import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Label } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import Modal from 'react-modal';
import Swal from 'sweetalert2'

import env from '../../../env/src_config';
import { headersState } from '../../../recoil/recoil';
import { isNull, modalStyles } from '../../../izUtils'
import SecuritySystemComponentLists from './lists/SecuritySystemComponentList'
import { transformer } from '../../../helpers/fields'
import { axiosError, errorStatus } from '../../../helpers/response';
import Spinner from '../../spinner/Spinner';
import PaginationTop from '../../Pagination/PaginationTop';
import PaginationBottom from '../../Pagination/PaginationBottom';
import AddSecuritySystemComponent from './modals/AddSecuritySystemComponent'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const SecuritySystemComponent = () => {
    const navigate = useNavigate();
    const { linkedSystemId } = useParams();
    
    const headers = useRecoilValue(headersState);
    const [securitySystemComponents, setSecuritySystemComponents] = useState(null);
    const [filters, setFilters] = useState(null);
    const [modalAddSecuritySystemComponent, setModalAddSecuritySystemComponent] = useState(false);

    useEffect(() => {
        getSecuritySystemComponents({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSecuritySystemComponents = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/client/facility/system/components/list/' + linkedSystemId, payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                if (!isNull(response.data.filters)) setFilters(transformer(response.data.filters));
                setSecuritySystemComponents(response.data.data);
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const handleDelete = (row) => {
        console.log(row)

        Swal.fire({
            text: t('facilities.linkedSystems.securitySystemComponent.delete'),
            icon: 'question',
            confirmButtonColor: 'var(--theme-default)',
            confirmButtonText: t('ok'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
        }).then(val => {
            console.log(val)
            if (val.isConfirmed) {
                axios.delete(env.api + '/api/client/facility/system/components/' + row.id, {headers}).then(response => {
                    if (response.data.state === 'success') {
                        getSecuritySystemComponents({});
                        Swal.fire({
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })
                    } else {
                        errorStatus(response.data, t);
                    }
                }).catch(error => {
                    axiosError(error, navigate);
                });
            }
        })
    }

    if (isNull(securitySystemComponents)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Label>{t('facilities.linkedSystems.securitySystemComponent.title')}</Label>
                <div>
                    <PaginationTop filters={filters} getData={clonedFilters => getSecuritySystemComponents(clonedFilters, false)} />
                    <SecuritySystemComponentLists data={securitySystemComponents} headers={headers} handleDelete={handleDelete} />
                    <PaginationBottom filters={filters} getData={clonedFilters => getSecuritySystemComponents(clonedFilters, false)} />
                </div>
                <div className='text-start'>
                    <Button color="primary" className="me-2" onClick={() => setModalAddSecuritySystemComponent(true)}>{t('facilities.linkedSystems.securitySystemComponent.add')}</Button>
                </div>

                {/* ADD NEW SECURITY SYSTEM COMPONENT */}
                <Modal
                    isOpen={modalAddSecuritySystemComponent}
                    onRequestClose={() => setModalAddSecuritySystemComponent(false)}
                    style={modalStyles}
                    contentLabel="Add security system component Modal"
                >
                    <AddSecuritySystemComponent
                        setModalAddSecuritySystemComponent={(val) => setModalAddSecuritySystemComponent(val)}
                        getSecuritySystemComponents={(val) => getSecuritySystemComponents(val)}
                    />
                </Modal>
            </Fragment>
        )
    }
    
}

export default SecuritySystemComponent