import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import axios from 'axios'

import env from '../../../env/src_config';
import { headersState } from '../../../recoil/recoil';
import { isNull } from '../../../izUtils'
import LinkedSystemList from './lists/LinkedSystemList'
import { transformer } from '../../../helpers/fields'
import { axiosError } from '../../../helpers/response';
import Spinner from '../../spinner/Spinner';
import PaginationTop from '../../Pagination/PaginationTop';
import PaginationBottom from '../../Pagination/PaginationBottom';
import AddUpdateLinkedSystem from './AddUpdateLinkedSystem'
import ContractComponent from '../contracts/ContractComponent';

const LinkedSystemComponent = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId, linkedSystemId } = useParams();
    
    const headers = useRecoilValue(headersState);
    const [linkedSystems, setLinkedSystems] = useState(null)
    const [filters, setFilters] = useState(null)

    useEffect(() => {
        if (facilityId === 'create') {
            setLinkedSystems([]);
            setFilters([]);
        } else {
            getLinkedSystems({});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLinkedSystems = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/client/facility/system/list/' + facilityId, payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                if (!isNull(response.data.filters)) setFilters(transformer(response.data.filters));
                setLinkedSystems(response.data.data);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    if (isNull(linkedSystems)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Col lg="6">
                    <Card className="ribbon-wrapper">
                        <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary">{t('facilities.linkedSystems.list.title')}</div>
                            <div>
                                <PaginationTop filters={filters} getData={clonedFilters => getLinkedSystems(clonedFilters, false)} />
                                <LinkedSystemList data={linkedSystems} />
                                <PaginationBottom filters={filters} getData={clonedFilters => getLinkedSystems(clonedFilters, false)} />
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className='text-end'>
                                <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/create')}>{t('facilities.linkedSystems.list.addNew')}</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                {!isNull(linkedSystemId) &&
                    <div id="linkedSystemContracts">
                        <hr />
                        <Row>
                            <Col md="6">
                                <AddUpdateLinkedSystem getLinkedSystems={() => getLinkedSystems({})} />
                            </Col>
                            {(linkedSystemId !== 'create') && 
                                <Col md="6">
                                    <ContractComponent />
                                </Col>
                            }
                        </Row>
                    </div>
                }
            </Fragment>
        )
    }
}

export default LinkedSystemComponent