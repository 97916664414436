import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardBody, Button, CardHeader, Collapse } from 'reactstrap'
import { useRecoilValue } from 'recoil';
import Board from '@asseinfo/react-kanban'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';
import moment from 'moment';

import env from '../../env/src_config'
import { headersState, tokenState } from '../../recoil/recoil'
import { isNull, displayDate } from '../../izUtils'
import { axiosError} from '../../helpers/response';
import Spinner from '../spinner/Spinner';
import { transformer } from '../../helpers/fields';
import Loader from '../spinner/Loader';
import Filter from '../filters/Filter';

const Kanban = () => {
    const {t} = useTranslation()
    const Navigate = useNavigate()
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);

    const [board, setBoard] = useState(null);
    const [filters, setFilters] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(null);
    const [loading, setLoading] = useState(false);

    const lsFilterName = 'AKODA.kanbanFilter';

    const hoverOverCard = useRef(false)

    const toggle = () => (setIsFilterOpen(!isFilterOpen))

    useEffect(() => {
        updateData(null, true)

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let refreshInterval = null;
        let refreshRate = 60000;
        // eslint-disable-next-line
        if (!isNull(env.dashboard_refresh) && env.dashboard_refresh !== '' && env.dashboard_refresh !== '${X_DASHBOARD_REFRESH}') {
            refreshRate = env.dashboard_refresh < 10000 ? 10000 : env.dashboard_refresh;
            refreshInterval = setInterval(() => {
                if ((isNull(hoverOverCard.current) || !hoverOverCard.current) && !document.hidden) {
                    updateData(filters, false)
                }
            }, refreshRate);
        }

        return () => clearInterval(refreshInterval);
    }, [filters]) // eslint-disable-line react-hooks/exhaustive-deps

    // Set hoverOverCard if user is hovering over the card item 
    useEffect(() => {
        const handleMouseOver = (element) => hoverOverCard.current = true
        const handleMouseOut = (element) => hoverOverCard.current = false

        const kanbanItems = document.getElementsByClassName('kanban-box');
        for (const element of kanbanItems) {
            element.addEventListener('mouseover', () => handleMouseOver(element));
            element.addEventListener('mouseout', () => handleMouseOut(element));
        }
    
      return () => {
        for (const element of kanbanItems) {
            element.removeEventListener('mouseover', () => handleMouseOver(element));
            element.removeEventListener('mouseout', () => handleMouseOut(element));
        }
      }
    }, [board])

    const updateData = (passedInputs, showLoading) => {
        showLoading && setLoading(true);
        let payload = {};

        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.filters = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.filters[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.filters[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/task/list', payload, {headers}).then(response => {
            let columns = []
            let keys = ["reported", "preview", "in_progress", "checkup",  "in_approval", "approved"]
            if (tokenData.parsedToken.type === 'accountant') keys = ["approved"]
            if (tokenData.parsedToken.type === 'technician') keys = ["reported", "preview", "in_progress"]
            keys.forEach(key => {
                let column = {
                    id: key,
                    title: t(`status.${key}`),
                    cards: []
                }
                if (!isNull(response.data.data[key])) column.cards = response.data.data[key]
                columns.push(column)
            })
            setBoard({columns});
            setFilters(transformer(response.data.filters))
            localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
            showLoading && setLoading(false);
        }).catch(error => {
            showLoading  && setLoading(false);
            axiosError(error, Navigate);
        });
    }

    const handleKanbanHeaderClick = (id) => {
        const cardsContainer = document.querySelector(`[data-rbd-droppable-id=${id}]`)
        const cardHeader = document.getElementById(`filter-icon-${id}`)

        if (!isNull(cardsContainer)) {
            if (cardsContainer.classList.contains('card-container-hide')) {
                cardHeader.classList.add('rotate-icon')
            } else {
                cardHeader.classList.remove('rotate-icon')
            }
        }

        cardsContainer.classList.toggle('card-container-hide')
    }

    const renderColumnHeader = useCallback(({ id, title, cards }) => {
        const cardsContainer = document.querySelector(`[data-rbd-droppable-id=${id}]`)
        let isCardOpen = true
        if (!isNull(cardsContainer)) isCardOpen = !cardsContainer.classList.contains('card-container-hide')
        let cardTitle = title
        if (tokenData.parsedToken.type === 'technician' && id === 'reported') cardTitle = t(`status.technicianReported`)

        return (
            <div key={id+'-'+title} id={id} className="react-kanban-column-header d-flex justify-content-between" onClick={() => handleKanbanHeaderClick(id)}>
                <span>{cardTitle + ' (' + cards.length + ')' }</span>
                <div id={'filter-icon-'+id} className={'filter-icon' + (isCardOpen ? ' rotate-icon' : '')}>
                    <i className="fa fa-chevron-down" style={{ fontSize: '12px' }}></i>
                </div>
            </div>
        )
    }, [tokenData.parsedToken.type]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderCard = (cardData) => {
        const { id, /*client,*/ facility, submitted_at, security_systems, assignees, permissions, important, available_time, incomplete_report, status, service_from, locked, reject_message, technician_notes } = cardData;
        const showAvailableTime = (["reported", "preview", "in_progress"].indexOf(status) !== -1);
        const showServiceFrom = (["in_progress", "checkup", "approved"].indexOf(status) !== -1);

        return (
            <div key={"task-"+id} className="kanban-item">
                <Link className={"kanban-box" + (important ? " important" : "" ) + (incomplete_report ? " incomplete" : "") + (reject_message ? " reject-message" : "") + (technician_notes ? " technician-notes" : "") } to={"/task/" + id + (permissions['task.update'] ? '/update' : '/get') }>
                    <div className='d-flex justify-content-between'>
                        {(!isNull(available_time) && showAvailableTime) &&
                            <span className={'badge ' + (parseInt(available_time/60) > 4  ? 'bg-primary' : 'bg-danger')}>
                                {t('thisMuch')}&nbsp;{(available_time/60) > 24 ? parseInt(available_time/60/24) + ' ' + t('days') : parseInt(available_time/60) + ' ' + t('hours')}
                            </span>
                        }
                        {!isNull(locked) &&
                            <span className="badge bg-warning">
                                <i className="icon-lock" style={{ color: '#000' }}></i>
                            </span>
                        }
                    </div>
                    <h6 className='mb-1'>{isNull(facility) ? '' : facility.name}</h6>
                    <div className="media">
                        <div className="media-body">
                            <p>{isNull(facility) ? '' : facility.location}</p>
                        </div>
                    </div>
                    <ul className="list">
                        {security_systems.map(ss => (
                            <li key={'ss-'+id+'-'+ss.id} style={{ display: 'block' }}>{ss.title}</li>
                        ))}
                    </ul>
                    <div className="d-flex justify-content-end">
                        <div className="customers">
                            <ul>
                                {assignees.length > 3 && 
                                    <li key="assigne-count" className="d-inline-block me-3">
                                        <p className="f-12">+{assignees.length - 3}</p>
                                    </li>
                                }
                                {assignees.map((assigne, index) => {
                                    if (index < 3) {
                                        return (
                                            <li key={"assigne-"+id+'-'+assigne.id} className="d-inline-block">
                                                <div className='assigne'>{assigne.name.split(' ').map(name => name[0])}</div>
                                            </li>
                                        )
                                    } else {
                                        return ''
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    {showServiceFrom ?
                        <div className="date">{t('kanban.serviceFrom')}:<br/>{displayDate(moment.utc(service_from))}</div>
                        :
                        <div className="date">{t('kanban.submittedAt')}:<br/>{displayDate(moment.utc(submitted_at))}</div>
                    }
                    {reject_message &&
                        <div className="reject-message mt-1">
                            {t('form.label.reject_message') + ': ' + reject_message}
                        </div>
                    }
                </Link>
            </div>
        )
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        updateData(null, true);
    }

    const filterFields = ['client', 'facility', 'assignee', 'security_system', 'submitted_at', 'service_from', 'admin_interaction']

    return (
        <Fragment>
            {!isNull(board) ?
                <Card>
                    {loading && <Loader />}
                    <CardHeader className='p-3'>
                        <div className='d-flex justify-content-start mb-4'>
                            {(tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') && <Button color="primary" onClick={() => Navigate('/task/create')}>{t('kanban.addApproval')}</Button>}
                            {tokenData.parsedToken.type === 'technician' && 
                                <div>
                                    <Button color="primary" className='mt-2' onClick={() => Navigate('/task/create-reported')}>{t('kanban.addReport')}</Button>
                                    <Button color="primary" className='mt-2 ms-2' onClick={() => Navigate('/task/create')}>{t('kanban.addApproval')}</Button>
                                </div>
                            }
                        </div>
                        <Accordion>
                            <div className="default-according style-1" id="accordionfilter">
                                <div>
                                    <Button color=/*"link"*/"secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={toggle}>
                                        {t('filters')}
                                        <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                            <i className="fa fa-chevron-down"></i>
                                        </div>
                                    </Button>
                                </div>
                                <Collapse isOpen={isFilterOpen}>
                                    <CardBody>
                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => updateData(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                    </CardBody>
                                </Collapse>
                            </div>
                        </Accordion>
                    </CardHeader>
                    <CardBody className='p-3'>
                        <div id="kanban">
                            <div className="kanban-container">
                                <div className="kanban-board">
                                    <main className="kanban-drag">
                                        <Board
                                            disableCardDrag={true}
                                            disableColumnDrag={true}
                                            renderColumnHeader={renderColumnHeader}
                                            renderCard={renderCard}
                                        >
                                            {board}
                                        </Board>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                :
                <Spinner />
            }
        </Fragment>
    )
}

export default Kanban