import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import Swal from "sweetalert2";

import env from '../env/src_config';
import LayoutRoutes from './LayoutRoutes';
import Loader from '../layout/loader/Loader';
import { isNull, parseJwt } from '../izUtils';
import { headersState, tokenState } from '../recoil/recoil';
import SignIn from '../auth/Signin';
// import SignUp from '../auth/SignUp';
import Verify from '../auth/Verify';
import PasswordResset from '../auth/PasswordResset';
import TaskApprove from '../auth/TaskApprove';
import Spinner from '../components/spinner/Spinner';

const Routers = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [headers, setHeaders] = useRecoilState(headersState);
    const [tokenData, setTokenData] = useRecoilState(tokenState);
    const { t } = useTranslation();

    useEffect(() => {
        let _headers = {...headers};
        let _tokenData = {...tokenData};
        let kuki = cookies;
        if (!isNull(kuki) && !isNull(kuki.token)) {
            const parsedToken = parseJwt(kuki.token);
            if (parsedToken.exp * 1000 < Date.now()) {
                Swal.fire({
                    title: t('logout.title'),
                    html: t('logout.text'),
                    icon: 'error',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('ok')
                });
                removeCookie('token', { path: '/' })
            } else {
                _headers["Authorization"] = 'Bearer ' + kuki.token;
                setHeaders(_headers);
                _tokenData.token = kuki.token;
                _tokenData.parsedToken = parsedToken;
                setTokenData(_tokenData);
            }
        } else {
            delete _headers["Authorization"];
            setHeaders(_headers);
            setTokenData({token: "", parsedToken: null});
        }

    }, [cookies]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.title = env.organization_name + " - desktop app";

        // Set organization colors
        const root = document.documentElement;
        root?.style.setProperty("--theme-default", env?.colors?.primary_color);
        root?.style.setProperty("--theme-default-hover", env?.colors?.primary_color_hover);
        root?.style.setProperty("--theme-secondary", env?.colors?.secondary_color);
        root?.style.setProperty("--theme-secondary-hover", env?.colors?.secondary_color_hover);

        const organization = window?.location?.hostname?.split('.')[0];
        if(!isNull(organization)) {
            document.body.classList.add(organization)
        }

    }, [])

    useEffect(() => {
        let link = document.querySelector("link[rel*='icon']");

        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }

        link.href = `/favicon/${env?.organization_name.toLowerCase().replace(' ', '')}.ico`;

    }, [])

    const signIn = (token) => setCookie('token', token, { path: '/' })

    if (isNull(tokenData.token)) {
        <Loader />
    } else {
        return (
            <BrowserRouter basename={'/'}>
                <Suspense fallback={<Spinner />}>
                    {tokenData.token.length === 0 ?
                        <Routes>
                            <Route exact path={"/login/:type?"} element={<SignIn signIn={signIn}/>} />
                            {/* <Route exact path={"/register"} element={<SignUp />} /> */}
                            <Route exact path={"/password-reset/:token"} element={ <PasswordResset />} />
                            <Route exact path={"/email/verify/:token"} element={<Verify />} />
                            <Route exact path={"/task/approve/:token"} element={<TaskApprove />} />
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                        :
                        <Routes>
                            <Route path={"/*"} element={<LayoutRoutes />} />
                        </Routes>
                    }
                </Suspense>
            </BrowserRouter>
        )
    }
}
export default Routers;