import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import {useDropzone} from 'react-dropzone'
import { Row, Col, Label } from 'reactstrap'
import axios from 'axios';
import moment from 'moment';

import env from '../../env/src_config';
import { headersState, tokenState } from '../../recoil/recoil';
import { axiosError, errorStatus } from '../../helpers/response';
import { isNull, bytesToSize } from '../../izUtils';
import { getFilePreview } from './getFilePreview';

const FileUpload = ({data, saveToLS, apiUrl, noDelete, inputType, taskData}) => {
    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);
    const [files, setFiles] = useState([])
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(null)

    useEffect(() => {
        if (!isNull(data.values) && data.values.length !== 0) {
            setLoadingAdd(true);
            Promise.all(
                data.values.map(async (file) => {
                    if (!isNull(file.title)) {
                        const splited = file.title.split('.')
                        file.ext = splited[splited.length - 1]
                        if (['png', 'jpg', 'jpeg'].indexOf(file.ext) !== -1) {
                            const getFile = await getFilePreview(file, headers, apiUrl);
                            file.preview =  URL.createObjectURL(getFile)
                        }
                        return file;
                    } else {
                        return {id: file};
                    }
                })
            ).then(data => {
                setFiles(data)
                localStorage.setItem(saveToLS, JSON.stringify(data));
                setLoadingAdd(false);
            });
        }

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const uploadFile = (file) => {
        setLoadingAdd(true);
        const formData = new FormData();
        formData.append('file', file);

        let url = '/api/public/file/';
        if (!isNull(apiUrl)) {
            url = apiUrl;
        } else if (!isNull(headers.Authorization) && headers.Authorization !== '') {
            url = '/api/private/file/';
        }

        axios.post(env.api + url + 'create', formData, {headers}).then(response => {
            if (!isNull(response.data.id)) {
                let lsFiles = JSON.parse(localStorage.getItem(saveToLS));
                let responseData = {...response.data};
                responseData.type = response.data.mime;
                responseData.preview =  URL.createObjectURL(file)

                if (isNull(lsFiles)) {
                    lsFiles = [responseData];
                } else {
                    lsFiles.push(responseData)
                }

                setFiles(lsFiles)
                localStorage.setItem(saveToLS, JSON.stringify(lsFiles));
            } else {
                errorStatus(response.data, t);
            }
            setLoadingAdd(false);
        }).catch(error => {
            setLoadingAdd(false);
            axiosError(error);
        });
    }

    const { getRootProps, getInputProps } = useDropzone({ 
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                if (inputType === 'createInventoryReceipt') {
                    // Change file name
                    const originalFile = file;
                    const { name, type, lastModified } = originalFile;

                    const splitedName = name.split('.');
                    const fileExt = splitedName[splitedName.length - 1];

                    let firstLettersName = ""
                    token?.parsedToken?.name?.split(' ').forEach(name => firstLettersName += name[0]);

                    let facilityName = '';
                    taskData?.data.forEach(input => {
                        if (input.name === "facility") {
                            console.log(input)
                            input.values.forEach(val => {
                                if (val.id === input.value) {
                                    facilityName = val.title
                                }
                            })
                        }
                    })

                    const newName = firstLettersName +  '-' + facilityName + '-' + moment().format('DDMMYYYY') +  '.' + fileExt;
                    const renamedFile = new File([file], newName, { type, lastModified });
                    uploadFile(renamedFile)
                } else {
                    uploadFile(file)
                }
            })
        }
    })

    const handleFile = (file, type) => {
        let url = '/api/public/file/';
        if (!isNull(apiUrl)) {
            url = apiUrl;
        } else if (!isNull(headers.Authorization) && headers.Authorization !== '') {
            url = '/api/private/file/';
        }

        if (type === 'get') {
            axios.get(env.api + url + file.id, {headers, responseType: 'blob'}).then(response => {
                const href = window.URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.download = file.title;
                a.href = href;
                a.click();
                a.href = '';
            }).catch(error => {
                axiosError(error);
            });
        } else if (type === 'delete') {
            if (!data.disabled) {
                setLoadingDelete(file.id);
                axios.delete(env.api + '/api/private/file/' + file.id, {headers}).then(response => { // Delete is only on private route
                    if (!isNull(response.data.state) && response.data.state === "success") {
                        let lsFiles = JSON.parse(localStorage.getItem(saveToLS));
                        if (!isNull(lsFiles)) lsFiles = lsFiles.filter(f => f.id !== file.id )
                        setFiles(lsFiles)
                        localStorage.setItem(saveToLS, JSON.stringify(lsFiles));
                    } else {
                        errorStatus(response.data, t);
                    }
                    setLoadingDelete(null);
                }).catch(error => {
                    setLoadingDelete(null);
                    axiosError(error);
                });
            }
        }
    }

    const displayFiles = useMemo(() => {
        return files.map(file => (
            <li key={file.id} className="file-box">
                <div className="file-top" onClick={() => handleFile(file, 'get')}>
                    {['png', 'jpg', 'jpeg'].indexOf(file.ext) !== -1 ?
                        <img src={file.preview}
                            alt={file.title}
                            className="task-image"
                            onLoad={() => { URL.revokeObjectURL(file.preview) }} // Revoke data uri after image is loaded
                        />
                        :
                        <i className={`fa fa-file-${file.ext}-o txt-primary`}></i>
                    }
                </div>
                <div className="file-bottom">
                    <h6 onClick={() => handleFile(file, 'get')}>{file.title}</h6>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <div className="mb-0 mt-1" style={{ opacity: '0.9' }}>{bytesToSize(file.size)}</div>
                        {(!data.disabled && !noDelete) && <i className= "icon-trash bigger-icon" onClick={() => handleFile(file, 'delete')}></i>}
                    </div>
                </div>
                {(!isNull(loadingDelete) && (loadingDelete === file.id)) && 
                    <div style={{ position: 'absolute', inset: '0', backgroundColor: '#00000030' }}>
                        <span className="loader-box">
                            <div className="loader-19"></div>
                        </span>
                    </div>
                }
            </li>
        ));
    }, [files, data.disabled, loadingDelete]) // eslint-disable-line react-hooks/exhaustive-deps

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'var(--theme-default)',
        borderStyle: 'dashed',
        backgroundColor: '#00aeef20',
        color: '#000',
        outline: 'none',
        transition: 'border .24s ease-in-out'
      };
      
    const style = {...baseStyle}

    if (data.display) {
        return (
            <Fragment>
                {(!data.disabled || (data.disabled && displayFiles.length !== 0)) && <Label>{t(`form.label.${data.name}`)}</Label>}
                <div className='file-content'>
                    <div className='file-manager '>
                        <div className='files'>
                            <Row>
                                {!data.disabled &&
                                    <Col sm="12">
                                        <div className='mb-2'>
                                            <div {...getRootProps({style})}>
                                                <input {...getInputProps()} />
                                                <p className='text-center'>{t('dragndrop')}</p>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                <Col sm="12">
                                    <div className={'d-flex flex-wrap mb-3' + (displayFiles?.length !== 0 ? ' attachments-container' : '')}>
                                        {displayFiles}
                                        {loadingAdd &&
                                            <span className="loader-box ms-5">
                                                <div className="loader-19"></div>
                                            </span>
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return null;
    }

}

export default FileUpload