import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import { Container, Card, CardBody, Button, Row, Col, Collapse } from 'reactstrap'

import env from '../../env/src_config';
import { headersState } from '../../recoil/recoil';
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import PaginationTop from '../../components/Pagination/PaginationTop';
import ArchiveList from '../../components/archive/list/ArchiveList';
import Filter from '../../components/filters/Filter';

const Archive = () => {
    const navigate = useNavigate();
    
    const headers = useRecoilValue(headersState);
    const [archive, setArchive] = useState(null)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);

    const lsFilterName = 'AKODA.archiveFilter';

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        getArchive(isNull(lsFilter) ? {} : lsFilter);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getArchive = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/task/archive/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setArchive(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName)
        getArchive({});
    }

    if (isNull(archive)) {
        return <Spinner />
    } else {
        const filterFields = ['client', 'facility', 'invoice_number', 'technician', 'admin', 'service_from', 'service_type', 'service_number']
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.archive')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('archive.list.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="archivefilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isFilterOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getArchive(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <PaginationTop filters={filters} getData={clonedFilters => getArchive(clonedFilters)} />
                                        <ArchiveList data={archive} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getArchive(clonedFilters)} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => navigate('/')}>{t('back')}</Button> 
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default Archive