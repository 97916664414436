import React, { useEffect, useState, Fragment } from 'react'
import { t } from 'i18next'
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Container, Row, Col, Card, CardBody, Button, CardFooter } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import { headersState } from '../../recoil/recoil';
import { isNull } from '../../izUtils';
import { linker, transformer } from '../../helpers/fields';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import DisplayFields from '../../helpers/displayFields';
import { axiosError, errorStatus } from '../../helpers/response';
import Spinner from '../../components/spinner/Spinner';
import ServiceTechnicians from '../../components/service/list/ServiceTechnicians'
import { changeStatus } from '../../helpers/changeStatus';

const SignService = () => {
    const {taskId, serviceId} = useParams()
    const Navigate = useNavigate();
    
    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const lsTechicians = 'AKODA.serviceTechnicians-sign';

    useEffect(() => {
        updateData(false, null, false);

        return () => {
            localStorage.removeItem(lsTechicians)
        }
    }, [serviceId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs, status) => {
        if (save) setDisableBtn(true)
        let payload = { save };

        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let selectedTechnicians = JSON.parse(localStorage.getItem(lsTechicians))
            if (!isNull(selectedTechnicians)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.technicians = selectedTechnicians.map(technician => {
                    return {
                        id: technician.id,
                        from: technician.from,
                        to: technician.to, 
                        programming_work: technician.programming_work,
                        remote_work: technician.remote_work,
                    }
                })
            }
        }

        axios.patch(env.api + '/api/service/' + serviceId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && response.data.valid) {
                    if (status) {
                        changeStatus(true, status, taskId ,headers, Navigate)
                    } else {
                        Swal.fire({
                            title:  t('saved'),
                            text:  response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        }).then(() => {
                            if (status) {
                                Navigate('/')
                            } else {
                                Navigate(-1)
                            }
                        })
                    }
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }
                setDisableBtn(false);
            }
            
            if (!isNull(response.data.data)) {
                let transformedData = transformer(response.data.data);
                // eslint-disable-next-line
                const filteredValues = transformedData.technicians.values.filter(tech => {
                    let isInside = false
                    transformedData.technicians.value.forEach(val => {
                        if (val.id === tech.id) isInside = true
                    });
                    
                    if (isInside) return tech
                })
                transformedData.technicians.values = filteredValues
                transformedData.technicians.disabled = true;
                setInputs(transformedData);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs, false);
    }

    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        if (!isNull(inputs[field])) {
            if (inputs[field].name === 'signature') {
                return linker(inputlinkerFields);
            } else {
                return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
            }
        } 
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Breadcrumbs grandParent={t('breadcrumb.kanban')} parent={t('breadcrumb.task')} title={t('breadcrumb.serviceSign')} />

                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('service.sign.title')}</div>
                                    <Row>
                                        <Col md="6">
                                            { ['technician_count', 'vehicle_count', 'work_description', 'service_type', 'service_type_other', 'submitted_at', 'materials'].map(field => mapLinker(field)) }
                                        </Col>
                                        <Col md="6">
                                            <ServiceTechnicians data={inputs.technicians} saveToLS={lsTechicians} showErrors={showErrors} getData={() => updateData(false, inputs)} type='sign' />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="6">
                                            { ['signature_status', 'signature_notes', 'signatory_name', 'signature'].map(field => mapLinker(field)) }
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <div>
                                            <Button color="danger" className="me-2 mb-2" disabled={disableBtn} onClick={() => Navigate(-1)}>{t('service.sign.fixData')}</Button>
                                        </div>
                                        <div>
                                            <Button color="primary" className="me-2 mb-2" disabled={disableBtn} onClick={() => updateData(true, inputs, false)}>{t('service.sign.save')}</Button>
                                            <Button color="primary" className="me-2 mb-2" disabled={disableBtn} onClick={() => updateData(true, inputs, 'checkup')}>{t('service.sign.saveClose')}</Button>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default SignService