import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Row, Col, Button, Input } from 'reactstrap'

import { isNull } from '../../../izUtils'

const SupervisorInput = ({data, onChange, onBlur, showErrors}) => {
    const { name, display, errors, title, disabled, mandatory} = data;
    const [values, setvalues] = useState([])

    useEffect(() => {
        if (isNull(data.value) || data.value === '' || data.value.length === 0) {
            setvalues([])
        } else {
            const dataValue = [];
            data.value.forEach(item => {
                dataValue.push(item);
            })

            setvalues(dataValue)
        }
    }, [JSON.stringify(data.value)]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e, type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = e.target.value
        setvalues(clonedValue)
    };

    const handleBlur = () => {
        if (!isNull(onBlur)) onBlur(values, name)
    };

    const handleCheck = (type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = !clonedValue[index][type]
        setvalues(clonedValue)
        if (!isNull(onBlur)) onBlur(clonedValue, name) 
    }

    const addNew = () => {
        let clonedValue = [...values]
        clonedValue.push({ name: null, phone: null, email: null, main_supervisor: false })
        setvalues(clonedValue)
    }

    const remove = (index) => {
        let clonedValue = [...values]
        let filteredValue = clonedValue.filter((item, i) => index !== i)
        setvalues(filteredValue)
        onBlur(filteredValue, name)
    }

    if (display) {
        return (
            <FormGroup style={{ position: 'relative' }}>
                {(!disabled || (disabled && values.length !== 0)) && <h6>{title + (mandatory ? " *" : "")}</h6>}
                {values.map((item, index) => (
                    <Row key={"supervisors-"+index} className='mb-2'>
                        <Col sm="12">
                            <Label>{t(`form.label.${name}.name`)}</Label>
                            <input 
                                className="form-control"
                                disabled={disabled}
                                type="text"
                                name={name+'-name'}
                                placeholder={t(`form.placeholder.${name}.name`)}
                                onChange={(e) => handleChange(e, 'name', index)}
                                value={isNull(item.name) ? '' : item.name}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Col sm="12">
                            <Label>{t(`form.label.${name}.phone`)}</Label>
                            <input 
                                className="form-control"
                                disabled={disabled}
                                type="text"
                                name={name+'-phone'}
                                placeholder={t(`form.placeholder.${name}.phone`)}
                                onChange={(e) => handleChange(e, 'phone', index)}
                                value={isNull(item.phone) ? '' : item.phone}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Col sm="12">
                            <Label>{t(`form.label.${name}.email`)}</Label>
                            <input 
                                className="form-control"
                                disabled={disabled}
                                type="email"
                                name={name+'-email'}
                                placeholder={t(`form.placeholder.${name}.email`)}
                                onChange={(e) => handleChange(e, 'email', index)}
                                value={isNull(item.email) ? '' : item.email}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Col sm="12">
                            <div className="custom-control custom-checkbox mt-2 form-check">
                                <div className="form-checkbox-group mb-1">
                                    <Input
                                        className={"custom-control-input"}
                                        id={name+'-main_supervisor-'+index}
                                        type="checkbox"
                                        onChange={() => handleCheck('main_supervisor', index)}
                                        checked={item.main_supervisor}
                                        disabled={disabled}
                                    />
                                    <Label className="checkmark" htmlFor={name+'-main_supervisor-'+index}></Label>
                                    <Label className="custom-control-label" htmlFor={name+'-main_supervisor-'+index} style={{ cursor: 'pointer' }} >{t(`form.label.${name}.main_supervisor`)}</Label>
                                </div>
                            </div>
                        </Col>
                        <Col sm="1" className='d-flex align-items-end my-2'>
                            {!disabled &&
                                <span onClick={() => remove(index)}>
                                    <i className="icon-trash bigger-icon"></i>
                                </span>
                            }
                        </Col>
                        {index + 1 !== values.length && <hr/>}
                    </Row>
                ))}
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && 
                    errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                {!disabled &&
                    <div className='text-start mt-3'>
                        <Button color="primary" onClick={addNew}>{t('form.supervisors.addNew')}</Button> 
                    </div>
                }
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default SupervisorInput