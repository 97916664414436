import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'

import { customStyles } from '../../../../izUtils'

const LinkedSystemLists = ({data, headers}) => {
    const { clientId, facilityId, linkedSystemId } = useParams()
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/' + state.id)
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.security_system'),
                selector: row => row.security_system.title,
                sortable: true,
                cell: row => {
                    if (linkedSystemId === row.id) {
                        return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.security_system.title}</div>
                    }
                    return row.security_system.title
                } 
            },
        ]
    }, [linkedSystemId]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default LinkedSystemLists