import React, {useState, useEffect, useMemo} from 'react'
import { t } from 'i18next'
import { FormGroup, Label } from 'reactstrap'
import { isNull } from '../../../izUtils'
import useComponentVisible from '../../../helpers/hooks/useComponentVisible'

const Select = ({data, onChange, showErrors, lsFilterName}) => {
    const { name, display, errors, title, values, disabled, mandatory} = data
    const isFilter = !isNull(lsFilterName)

    const [value, setvalue] = useState("")
    const [search, setSearch] = useState("");
    const { ref, isComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    useEffect(() => {
        if (search.length !== 0) {
            setSearch("")
        }
    }, [JSON.stringify(data.values)]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        setvalue(e.target.value)

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = e.target.value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))

                        const callBEAfterChange = ['client', 'facility'] // BE needs to be called after changing one of these filters, because they are related to other filters
                        if (callBEAfterChange.indexOf(name) !== -1) {
                            onChange(e.target.value, name)
                        }
                        break;
                    }
                }
            }
        } else {
            onChange(e.target.value, name)
        }
    };

    const options = useMemo(() => {
        if (!isNull(values)) {
            return values.map((option, index) => {
                if ( (search.length === 0) || (!isNull(option.title) && option.title.toLowerCase().includes(search.toLowerCase())) ) {
                    return <li key={name + '-' + index} onClick={() => handleChange({ target: {value: option.id} })}>{option.title}</li>
                } else {
                    return null
                }
            });
        } else {
            return null;
        }
        // eslint-disable-next-line
    }, [values, search]) 

    const displayedValue = useMemo(() => {
        let displayedName = t('defaultSelect');
        if (!isNull(values)) {
            values.forEach(option => {
                if (option.id === value) displayedName = option.title;
            });
        }
        return displayedName;
    }, [value, values])

    let showMoreInfo = useMemo(() => {
        if ((name === 'facility') && !isNull(value) && (value.length !== 0)) {
            let findValue = '';
            if (!isNull(values)) findValue = values.find(option => option.id === value)
            if (!isNull(findValue)) {
                return (
                    <div>
                        {(!isNull(values) && values.length !== 0) && 
                            <ul className='mt-1'>
                                {!isNull(findValue.google_location) && 
                                    <li>
                                        {findValue.google_location}
                                        <a href={'https://www.google.com/maps/place/' + findValue.google_location} target="_blank" rel="noreferrer" className='ms-2'><i className="fa fa-map-marker"></i></a>
                                    </li>
                                }
                                {!isNull(findValue.facility_notes) && <li><b>{t('form.label.notes') + ': '}</b>{findValue.facility_notes}</li>}
                                {!isNull(findValue.technician_expertise) && <li><b>{t('form.label.technician_expertise') + ': '}</b>{findValue.technician_expertise}</li>}
                                {!isNull(findValue.salesperson_expertise) && <li><b>{t('form.label.salesperson_expertise') + ': '}</b>{findValue.salesperson_expertise}</li>}
                            </ul>
                        }
                    </div>
                )
            }
        } else {
            return null;
        }
    }, [value, values, name])
    
    if (display) {
        return (
            <FormGroup>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <div ref={ref} style={{ width: '100%', position: 'relative' }}>
                    <div className='form-control digits form-select' style={ disabled ? { zIndex: '200', background: '#ededed', cursor: 'not-allowed' } : { zIndex: '200' } }>
                        {displayedValue}
                    </div>
                    {/* DROPDOWN */}
                    {!disabled &&
                        <div className="select-search-dropdown" style={{ display: isComponentVisible ? 'block' : 'none' }}>
                            <input className="form-control" id="search" value={search} onChange={(e) => setSearch(e.target.value)} disabled={disabled} />
                            <div className="select-search-options">
                                <ul>
                                    <li key={'default-option'} onClick={() => handleChange({ target: {value: ""} })}>{t('defaultSelect')}</li>
                                    {options}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors[0]}</div>}
                {!isNull(showMoreInfo) && showMoreInfo}
            </FormGroup>

        )
    } else {
        return null
    }
}

export default Select