import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'

import { customStyles, isNull } from '../../../../izUtils'

const SecuritySystemComponentLists = ({data, handleDelete}) => {
    const { linkedSystemId } = useParams()

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.manufacturer'),
                sortable: false,
                cell: row => (!isNull(row.manufacturer) && row.manufacturer.length !== 0) ? row.manufacturer[0].title : ''
            },
            {
                name: t('table.system'),
                sortable: false,
                cell: row => (!isNull(row.system) && row.system.length !== 0) ? row.system[0].title : ''
            },
            {
                name: t('table.central_station'),
                sortable: false,
                cell: row => (!isNull(row.central_station) && row.central_station.length !== 0) ? row.central_station[0].title : ''
            },
            {
                name: t('table.component_notes'),
                sortable: false,
                cell: row => (!isNull(row.component_notes)) ? row.component_notes : ''
            },
            {
                name: "",
                sortable: false,
                cell: row => {
                    return (
                        <div className='m-auto' onClick={() => handleDelete(row)}>
                            <i className="icon-trash bigger-icon"></i>
                        </div>
                    )
                }
            },

        ]
    }, [linkedSystemId]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
            />
        </FormGroup>
    )
}

export default SecuritySystemComponentLists